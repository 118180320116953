import React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import "../styles/landing.scss";

export default function IndexPage({ data }) {
  return (
    <Container fluid="md" className="contain">
      <title>Wealthy Healthy Juices</title>
      <div className="inner">
        <img src={data.file.publicURL} alt="wealthy healthy juices logo" />
        <h1>Coming Soon</h1>
      </div>
    </Container>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "whj1.png" }) {
      publicURL
    }
  }
`;
